@import "../../../styles/colors";

.team-people-cont {
  // position: relative;
  // padding-right: 10px;
  display: flex;
  flex-wrap: wrap;
}

// .team-people-toggle-btn {
//   border: none;
//   background-color: transparent;
//   color: $dark;
//   position: absolute;
//   right: 0px;
//   top: 20px;
//   font-size: 26px;
// }

.team-people-person-userthumb-cont {
  margin-left: -10px;
  display: inline-block;
  box-sizing: border-box;

  &.more-icon-cont {
    height: 43px;
    width: 43px;
  }

  &.first-userthumb {
    margin-left: 0px;

    &.team-people-person-userthumb-cont-full-row {
      margin-top: 30px;
    }
  }

  &.team-people-person-userthumb-cont-full-row {
    padding: 8px 0px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: 0px;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.team-people-person-userthumb {
  &.unavailable-profile {
    background-color: $grey-lighter;

    .cc-userthumb-no-img {
      background-color: $grey;
    }
  }
}

.team-people-person-info-cont {
  display: inline-flex;
  padding-left: 10px;
  padding-right: 30px;
  color: $black;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  font-size: 14px;
  position: relative;
  max-width: calc(100% - 43px);
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
}

.team-people-person-more-icon {
  color: $white;
  background-color: $dark;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  font-size: 13px;

  &:hover {
    cursor: pointer;
  }
}

.team-people-person-info-name {
  margin: 0px;
  font-weight: bold;
  width: 100%;
}

.team-people-person-info-jobtitle {
  margin: 0px;
  font-weight: normal;
  width: 100%;

  &.profile-is-unavailable {
    font-weight: bold;
    color: $grey;
  }
}

.team-people-person-info-btn {
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: transparent;
  border: none;
  width: 30px;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  color: $grey;

  &:hover {
    cursor: pointer;
  }

  .main.dark-mode & {
    * {
      fill: $white !important;
    }
  }
}
