@keyframes fadeOutAndIn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    pointer-events: none;
  }
  100% {
    opacity: 1;
    pointer-events: none;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0.4;
    transform: scale(0.2);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bgPatternSlide {
  0% {
    background-position-x: 0px;
    background-position-y: 0px;
  }
  100% {
    // background-position-x: -33px;
    // background-position-y: -36px;
    background-position-x: -200px;
    background-position-y: -174px;
  }
}

@keyframes birdFloat {
  0% {
    transform: translate3d(0px, 0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate3d(24px, 10px, 0px) rotate(10deg);
  }
  50% {
    transform: translate3d(0px, 0px, 0px) rotate(0deg);
  }
  75% {
    transform: translate3d(-24px, -10px, 0px) rotate(-10deg);
  }
  100% {
    transform: translate3d(0px, 0px, 0px) rotate(0deg);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-175%);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(175%);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideInFromLeftSlight {
  0% {
    opacity: 0;
    transform: translateX(-125px);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes slideInFromRightSlight {
  0% {
    opacity: 0;
    transform: translateX(125px);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes slideInFromBottomSlight {
  0% {
    opacity: 0;
    transform: translateY(75px);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
  }
}
