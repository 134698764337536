@import "./colors";
@import "./animations";

// * CSS Reset /START -----------------------------
// * ----------------------------------------------

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
// iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
// * CSS Reset /END -------------------------------
// * ----------------------------------------------

// * FONTS ----------------------------------------
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 7, 2019 */
@font-face {
  font-family: "gilroyextrabold";
  src: url("/gilroy-extrabold-webfont.woff2") format("woff2"),
    url("/gilroy-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "groteskbold";
  src: url("/hkgrotesk-bold-webfont.woff2") format("woff2"),
    url("/hkgrotesk-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "groteskitalic";
  src: url("/hkgrotesk-italic-webfont.woff2") format("woff2"),
    url("/hkgrotesk-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "grotesklight";
  src: url("/hkgrotesk-light-webfont.woff2") format("woff2"),
    url("/hkgrotesk-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "grotesklight_italic";
  src: url("/hkgrotesk-lightitalic-webfont.woff2") format("woff2"),
    url("/hkgrotesk-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "grotesk";
  src: url("/hkgrotesk-regular-webfont.woff2") format("woff2"),
    url("/hkgrotesk-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
// * END FONTS ------------------------------------------------

#main {
  min-height: 100vh;
  max-width: 100vw;
  background-color: $dark;
  color: $white;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;

  &.main-div-is-blurred {
    transition: filter 180ms ease-in-out;
    filter: blur(3px);
  }
}

body {
  overflow-x: hidden;

  *::selection {
    // background: $disc-yellow-light;
    background: $dark-background;
    color: $white;
  }

  &.no-animations-please {
    * {
      animation: none !important;
      transition: none !important;
    }
  }

  * {
    &:hover,
    &:focus {
      outline-color: $dark-background;
    }
  }
}

.loading-crane-cont {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .team-tools-crane {
    animation: birdFloat 3200ms ease-in-out infinite;
  }
}

.disc-grid-bg {
  border-radius: 6px;
  // * [1] - Mimicking Original KVault Designs
  background: linear-gradient(
      133.15deg,
      lighten($disc-d-light, 5%) 3.02%,
      $disc-d-light 100%
    ),
    linear-gradient(
      132.3deg,
      lighten($disc-i-light, 5%) 8.78%,
      $disc-i-light 100%
    ),
    linear-gradient(
      136.49deg,
      lighten($disc-c-light, 5%) 10.1%,
      $disc-c-light 100%
    ),
    linear-gradient(
      133.03deg,
      lighten($disc-s-light, 5%) 9.27%,
      $disc-s-light 100%
    );
  // * [2] - Hakha's Design
  // background: linear-gradient(133.15deg, $disc-d 3.02%, $disc-d-dark 100%),
  //   linear-gradient(132.3deg, $disc-i 8.78%, $disc-i-dark 100%),
  //   linear-gradient(136.49deg, $disc-c 10.1%, $disc-c-dark 100%),
  //   linear-gradient(133.03deg, $disc-s 9.27%, $disc-s-dark 100%);
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: top left, top right, bottom left, bottom right;

  &.disc-grid-bg-with-grid {
    position: relative;

    &::after {
      content: "";
      border: 1px solid rgba(0, 0, 0, 0.1);
      width: 50%;
      height: 50%;
      box-sizing: border-box;
      position: absolute;
      left: 25%;
      top: 25%;
    }
  }
}

button {
  outline-color: $dark-background;
}

.title {
  // * .title-normal
  &-normal {
    font-family: "grotesk";
  }

  // * .title-bold
  &-bold {
    font-family: "groteskbold";
  }

  // * .title-light
  &-light {
    font-family: "grotesklight";
  }
}
