@import "../../../styles/colors";

.person-disc-graph-keywords {
  margin-bottom: 0px;
  font-size: 12px;
  color: $dark;
  // font-weight: bold;
}

.person-disc-graph-toggle-btn-cont {
  padding-top: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.person-disc-graph-graph-cont {
  margin-top: 40px;
  box-sizing: border-box;
}

.person-disc-graph-toggle-btn {
}
