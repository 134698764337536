@import "../../../../styles/colors";

.cc-disc-plot-graph-plot-point-focused-cont {
  padding: 4px 0px;
  box-sizing: border-box;
  width: calc(200% / 3);
  text-align: center;
  font-weight: bold;
  position: absolute;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.2);
  font-size: 18px;
  border-bottom: 3px solid;
  color: transparent;

  &.cc-disc-plot-graph-plot-point-focused-cont-flipped {
    border-top: 3px solid;
    border-bottom: none;
  }

  &.cc-disc-plot-graph-plot-point-focused-cont-d {
    color: $disc-d;
    border-color: lighten($disc-d, 10%);
    color: transparent;
  }

  &.cc-disc-plot-graph-plot-point-focused-cont-i {
    color: $disc-i;
    border-color: lighten($disc-i, 10%);
    color: transparent;
  }

  &.cc-disc-plot-graph-plot-point-focused-cont-s {
    color: $disc-s;
    border-color: lighten($disc-s, 10%);
    color: transparent;
  }

  &.cc-disc-plot-graph-plot-point-focused-cont-c {
    color: $disc-c;
    border-color: lighten($disc-c, 10%);
    color: transparent;
  }
}

.cc-disc-plot-graph-plot-point-focused-blurb-cont {
  position: absolute;
  top: 100%;
  padding: 8px 6px;
  box-sizing: border-box;
  color: $dark-background;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 1);
  // background: radial-gradient(rgba(255, 255, 255, 0.65) 0% -10%, transparent);

  &.cc-disc-plot-graph-plot-point-focused-blurb-cont-move-to-top {
    top: unset;
    bottom: 0%;
  }
}

.cc-disc-plot-graph-plot-point-focused-blurb-span {
  margin: 0px;
  line-height: 16px;
}

.cc-disc-plot-graph-plot-point {
  border-radius: 50%;
  min-width: 4px;
  min-height: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: top 800ms ease-in-out, width 800ms ease-in-out,
    height 800ms ease-in-out;
  z-index: 11;
  background-color: $grey;

  &.cc-disc-plot-graph-plot-point-d {
    // background: radial-gradient(
    //   50% 50% at 50% 50%,
    //   $white 0% 20%,
    //   $disc-d 50% 100%
    // );
    // * BACK UPS ==================
    background: -webkit-radial-gradient(
      $disc-d 0% 25%,
      transparent 27% 56%,
      $disc-d 58% 100%
    );
    background: -moz-radial-gradient(
      $disc-d 0% 25%,
      transparent 27% 56%,
      $disc-d 58% 100%
    );
    background: -o-radial-gradient(
      $disc-d 0% 25%,
      transparent 27% 56%,
      $disc-d 58% 100%
    );
    background: -ms-radial-gradient(
      $disc-d 0% 25%,
      transparent 27% 56%,
      $disc-d 58% 100%
    );
    // * BACK UPS ==================
    // * Actual ====================
    background: radial-gradient(
      $disc-d 0% 25%,
      transparent 27% 56%,
      $disc-d 58% 100%
    );
  }

  &.cc-disc-plot-graph-plot-point-i {
    // background: radial-gradient(
    //   50% 50% at 50% 50%,
    //   $white 0% 20%,
    //   $disc-i 50% 100%
    // );
    // * BACK UPS ==================
    background: -webkit-radial-gradient(
      $disc-i 0% 25%,
      transparent 27% 56%,
      $disc-i 58% 100%
    );
    background: -moz-radial-gradient(
      $disc-i 0% 25%,
      transparent 27% 56%,
      $disc-i 58% 100%
    );
    background: -o-radial-gradient(
      $disc-i 0% 25%,
      transparent 27% 56%,
      $disc-i 58% 100%
    );
    background: -ms-radial-gradient(
      $disc-i 0% 25%,
      transparent 27% 56%,
      $disc-i 58% 100%
    );
    // * BACK UPS ==================
    // * Actual ====================
    background: radial-gradient(
      $disc-i 0% 25%,
      transparent 27% 56%,
      $disc-i 58% 100%
    );
  }

  &.cc-disc-plot-graph-plot-point-s {
    // background: radial-gradient(
    //   50% 50% at 50% 50%,
    //   $white 0% 20%,
    //   $disc-s 50% 100%
    // );
    // background: radial-gradient(
    //   $disc-s 0% 20%,
    //   transparent 22% 32%,
    //   $disc-s 34% 41%,
    //   transparent 43% 51%,
    //   $disc-s 53% 100%
    // );
    // * BACK UPS ==================
    background: -webkit-radial-gradient(
      $disc-s 0% 25%,
      transparent 27% 56%,
      $disc-s 58% 100%
    );
    background: -moz-radial-gradient(
      $disc-s 0% 25%,
      transparent 27% 56%,
      $disc-s 58% 100%
    );
    background: -o-radial-gradient(
      $disc-s 0% 25%,
      transparent 27% 56%,
      $disc-s 58% 100%
    );
    background: -ms-radial-gradient(
      $disc-s 0% 25%,
      transparent 27% 56%,
      $disc-s 58% 100%
    );
    // * BACK UPS ==================
    // * Actual ====================
    background: radial-gradient(
      $disc-s 0% 25%,
      transparent 27% 56%,
      $disc-s 58% 100%
    );
  }

  &.cc-disc-plot-graph-plot-point-c {
    // background: radial-gradient(
    //   50% 50% at 50% 50%,
    //   $white 0% 20%,
    //   $disc-c 50% 100%
    // );
    // * BACK UPS ==================
    background: -webkit-radial-gradient(
      $disc-c 0% 25%,
      transparent 27% 56%,
      $disc-c 58% 100%
    );
    background: -moz-radial-gradient(
      $disc-c 0% 25%,
      transparent 27% 56%,
      $disc-c 58% 100%
    );
    background: -o-radial-gradient(
      $disc-c 0% 25%,
      transparent 27% 56%,
      $disc-c 58% 100%
    );
    background: -ms-radial-gradient(
      $disc-c 0% 25%,
      transparent 27% 56%,
      $disc-c 58% 100%
    );
    // * BACK UPS ==================
    // * Actual
    background: radial-gradient(
      $disc-c 0% 25%,
      transparent 27% 56%,
      $disc-c 58% 100%
    );
  }

  &.cc-disc-plot-not-selected {
    // * BACK UPS ==================
    background: -webkit-radial-gradient(
      50% 50% at 50% 50%,
      $grey-mid 0% 50%,
      $grey 50% 100%
    );
    background: -moz-radial-gradient(
      50% 50% at 50% 50%,
      $grey-mid 0% 50%,
      $grey 50% 100%
    );
    background: -o-radial-gradient(
      50% 50% at 50% 50%,
      $grey-mid 0% 50%,
      $grey 50% 100%
    );
    background: -ms-radial-gradient(
      50% 50% at 50% 50%,
      $grey-mid 0% 50%,
      $grey 50% 100%
    );
    // * BACK UPS ==================
    // * Actual ====
    background: radial-gradient(
      50% 50% at 50% 50%,
      $grey-mid 0% 50%,
      $grey 50% 100%
    );
  }

  &.cc-disc-plot-is-selected {
  }

  &.cc-disc-plot-can-be-selected {
    transition: transform 400ms ease-in-out;
    transform-origin: bottom right;

    &:hover {
      cursor: pointer;
      transform: scale(1.55) translate3d(-12.5%, -12.5%, 0px);
    }
  }

  &.cc-disc-plot-can-is-hovered {
    z-index: 20 !important;
  }
}

.cc-disc-plot-graph-plot-point-withgradient-inner {
  position: absolute;
  //   top: 25%;
  //   left: 25%;
  // top: 50%;
  // left: 50%;
  // width: 50%;
  // height: 50%;
  // background-color: $grey-mid;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    $grey-mid 0% 50%,
    transparent 50% 100%
  );
  border-radius: 50%;
  // transform: translate3d(-50%, -50%, 0px);
}

.cc-disc-plot-graph-plot-point-value {
  margin: 0px;
  padding: 0px;
  position: absolute;
  font-size: 8px;
  font-weight: bold;
  left: 0px;
  top: -14px;
  z-index: 1000;
  color: $white;
  border-radius: 2px;
  padding: 2px 3px;
  box-sizing: border-box;
  transform: translate3d(0%, 0px, 0px);
  animation: fadeIn 280ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  .cc-disc-plot-graph-plot-point:hover & {
    transform: scale(0.65) translate3d(-12.5%, 12.5%, 0px);
  }

  &.cc-disc-plot-graph-plot-point-value-d {
    background-color: $disc-d;
  }

  &.cc-disc-plot-graph-plot-point-value-i {
    background-color: $disc-i;
  }

  &.cc-disc-plot-graph-plot-point-value-s {
    background-color: $disc-s;
  }

  &.cc-disc-plot-graph-plot-point-value-c {
    background-color: $disc-c;
  }
}

.cc-disc-plot-graph-plot-point-userthumb {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
}

// .cc-disc-plot-graph-plot-point-line {
//   height: 1px;
//   left: 0px;
//   width: 100%;
//   border-bottom: 1px solid $grey;
//   opacity: 0;
//   position: absolute;
//   z-index: 10;
//   transition: opacity 280ms ease-in-out;
//   transform: translateY(-1px);

//   .cc-disc-plot-graph-plot-point:hover + & {
//     opacity: 1;
//   }
// }

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
