@import "../../../styles/colors";
@import "../../../styles/animations";

.full-screen-wrapper {
  padding: 80px 12px 12px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  display: flex;
  // justify-content: center;
  // flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  z-index: 50;
  animation: slideInFromRight 400ms ease-in-out;
}
.full-screen-userthumb {
}

.full-screen-wrapper-msg {
  // margin-top: calc(50vh - 35px);
  margin: 0px;
  padding-top: 20px;
  box-sizing: border-box;
  //   margin-top: calc(50vh - 120px);
  //   max-height: 75px;
}

.full-screen-wrapper-submsg {
  margin: 0px;
  padding-top: 20px;
  box-sizing: border-box;
  font-size: 10px;
  color: $dark;
}

.full-screen-wrapper-help-section-title {
  margin: 50px 0px 0px;
  padding-top: 20px;
  border-top: 1px solid $grey-lighter;
  color: $grey;
  font-size: 14px;
  width: 100%;
  text-align: left;
}

.full-screen-wrapper-help-cont {
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
}

.full-screen-wrapper-help-details {
  width: 100%;
  color: $grey;

  &:hover {
    cursor: pointer;
  }
}

.full-screen-wrapper-help-title {
  font-size: 10px;
  font-weight: bold;
}

.full-screen-wrapper-help-text {
  font-size: 12px;
  color: $dark-background;
  line-height: 10px;
}

.full-screen-wrapper-btns-cont {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  // max-height: 35px;
}

.full-screen-wrapper-btn {
  margin: 0px 5px;
  max-width: calc(50% - 10px);
  height: 35px;
  width: 150px;

  &:hover {
    cursor: pointer;
  }

  .full-screen-wrapper-btn-link & {
    max-width: 100%;
  }
}

.full-screen-wrapper-btn-link {
  text-decoration: none;
  display: block;
  max-width: calc(50% - 10px);
}
