@import "../../../styles/colors";

.reset-password-cont {
  width: 100%;
}

.reset-password-error-msg {
  margin: 0px;
  padding: 4px 0px;
  box-sizing: border-box;
  color: $red;
  font-size: 11px;
  font-weight: bold;
}

.reset-password-label {
  margin: 0px;
  padding: 2px 0px;
  box-sizing: border-box;
  font-weight: bold;
  color: $white;
}

.reset-password-input-cont {
  width: 100%;

  &.reset-password-input-cont-invalid {
    border-color: $red;

    .reset-password-input {
      outline-color: $red;
    }
  }
}

.reset-password-input {
}

.reset-password-buttons-cont {
  padding: 20px 0px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password-button {
  margin: 0px 5px;
  min-width: 100px;
  min-height: 35px;
}
