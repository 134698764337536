@import "../../../styles/colors";

.person-comm-tips-cont {
  width: 100%;
}

.person-comm-tips-subtitle {
  margin: 0px;
  padding-top: 20px;
  font-size: 14px;
  box-sizing: border-box;

  &.person-comm-tips-subtitle-do-these {
    color: $green;
  }

  &.person-comm-tips-subtitle-dontdo-these {
    color: $red;
  }
}

.person-comm-tips-list {
  margin: 0px;
  padding: 20px 0px 0px 20px;
}

.person-comm-tips-list-item {
  font-size: 12px;
  color: $dark;
}
