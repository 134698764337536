@import "../../../styles/colors";

.recently-retrieved-main-cont {
  padding: 0px 8px;
  width: 100%;
  box-sizing: border-box;
}

.recently-retrieved-title {
  margin: 0px;
  padding: 15px 0px;
  box-sizing: border-box;
  color: $dark;
  font-size: 22px;
}

.recently-retrieved-people-list {
  margin: 0px;
  padding: 15px 0px;
  list-style: none;
}

.recently-retrieved-person-cont {
  box-sizing: border-box;
}

.recently-retrieved-person-cont-button-wrapper {
  padding: 6px 0px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  text-align: left;
}

.recently-retrieved-person-userthumb {
}

.recently-retrieved-person-info-cont {
  padding-left: 12px;
  padding-right: 30px;
  flex-basis: calc(100% - 50px);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: $dark;
  position: relative;
}

.recently-retrieved-person-info-name {
  flex-basis: 100%;
  color: inherit;
  font-weight: bold;
  margin: 0px;
  font-size: 16px;
}

.recently-retrieved-person-info-jobtitle {
  flex-basis: 100%;
  color: inherit;
  margin: 0px;
  font-size: 12px;
}

.recently-retrieved-person-info-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  font-size: 22px;
  color: $dark;

  &:hover {
    cursor: pointer;
  }
}
