@import "../../../styles/colors";

.person-header-cont {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}
.person-header-cont-images-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.person-header-disc-profile-icon {
  // .main.dark-mode & {
  //   fill: initial !important;
  // }
}

.person-header-disc-profile-title {
  margin: 0px 0px -20px;
  padding: 0px 0px 0px;
  color: $dark;
  font-size: 10px;
  box-sizing: border-box;
  text-align: center;
}

.person-header-userthumb {
  margin: 0px;
}

.person-header-name {
  margin: 0px;
  padding: 8px 0px 4px;
  text-align: left;
  font-size: 24px;
  font-weight: bold;
}

.person-header-jobtitle {
  margin: 0px;
  padding: 4px 0px;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
}

.person-header-jobtitle-contact-info {
  margin: 0px;
  padding: 4px 0px;
  box-sizing: border-box;
  color: $dark;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.person-header-jobtitle-contact-icon-cont {
  margin-right: 20px;
  padding: 0px 4px;
  box-sizing: border-box;

  .main.dark-mode & {
    * {
      fill: $darkmode-text !important;
    }
  }
}

.person-header-external-link-wrapper {
  margin: 0px;
  padding: 8px 0px;
  color: $dark;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
}

.person-header-external-link {
  padding: 4px 0px;
  color: inherit;
  font-size: inherit;

  &:hover {
    cursor: pointer;
  }
}

.person-header-link-domain-name-divider {
  padding: 0px 8px;
  color: $grey;
  box-sizing: border-box;
}

.person-header-link-domain-name {
  color: $grey;
  max-width: calc(50% - 12px);
  white-space: nowrap;
}
