@import "../../../../styles/colors";

.cc-loading-square-cont {
  position: relative;
  background-color: transparent;
}

// .cc-loading-square {
//   animation: seperate 1200ms ease-in-out infinite;
// }

.cc-loading-square-image-cont {
  bottom: -55px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  background-color: transparent;
}

.cc-loading-square-image {
  opacity: 0.4;
}

.cc-loading-square-1 {
  position: absolute;
  top: 0px;
  left: 0px;
  max-width: 50%;
  max-height: 50%;
  fill: $disc-d !important;
  background-color: $disc-d;
  border-radius: 3px 0px 0px 0px;
  animation: square1 5000ms ease-in-out infinite;

  &:after {
    content: "D";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: inherit;
    color: $dark-background;
    z-index: 101;
    // opacity: 0.4;
  }
}

.cc-loading-square-2 {
  position: absolute;
  top: 0px;
  left: 50%;
  max-width: 50%;
  max-height: 50%;
  fill: $disc-i !important;
  background-color: $disc-i;
  border-radius: 0px 3px 0px 0px;
  animation: square2 5000ms ease-in-out infinite;

  &:after {
    content: "I";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: inherit;
    color: $dark-background;
    z-index: 101;
    // opacity: 0.4;
  }
}

.cc-loading-square-3 {
  position: absolute;
  top: 50%;
  left: 0px;
  max-width: 50%;
  max-height: 50%;
  fill: $disc-c !important;
  background-color: $disc-c;
  border-radius: 0px 0px 0px 3px;
  animation: square3 5000ms ease-in-out infinite;

  &:after {
    content: "C";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: inherit;
    color: $dark-background;
    z-index: 101;
    // opacity: 0.4;
  }
}

.cc-loading-square-4 {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 50%;
  max-height: 50%;
  fill: $disc-s !important;
  background-color: $disc-s;
  border-radius: 0px 0px 3px 0px;
  animation: square4 5000ms ease-in-out infinite;

  &:before {
    content: "S";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: inherit;
    color: $dark-background;
    z-index: 101;
    // opacity: 0.4;
  }
}

// @keyframes square1 {
//   0% {
//     top: 0px;
//     left: 0px;
//     transform: scale(1);
//     border-radius: 0px;
//   }
//   12% {
//     top: -20px;
//     left: -20px;
//     transform: scale(0.35);
//     border-radius: 50%;
//   }
//   25% {
//     top: 0px;
//     left: 0px;
//     transform: scale(1);
//     border-radius: 0px;
//   }
//   100% {
//     top: 0px;
//     left: 0px;
//     transform: scale(1);
//   }
// }

// @keyframes square2 {
//   0% {
//     top: 0px;
//     left: 50%;
//     transform: scale(1);
//     border-radius: 0px;
//   }
//   25% {
//     top: 0px;
//     left: 50%;
//     transform: scale(1);
//     border-radius: 0px;
//   }
//   37% {
//     top: -20px;
//     left: calc(50% + 20px);
//     transform: scale(0.35);
//     border-radius: 50%;
//   }
//   50% {
//     top: 0px;
//     left: 50%;
//     transform: scale(1);
//     border-radius: 0px;
//   }
//   100% {
//     top: 0px;
//     left: 50%;
//     transform: scale(1);
//   }
// }

// @keyframes square3 {
//   0% {
//     top: 50%;
//     left: 0px;
//     transform: scale(1);
//     border-radius: 0px;
//   }
//   75% {
//     top: 50%;
//     left: 0px;
//     transform: scale(1);
//     border-radius: 0px;
//   }
//   87% {
//     top: calc(50% + 20px);
//     left: -20px;
//     transform: scale(0.35);
//     border-radius: 50%;
//   }
//   100% {
//     top: 50%;
//     left: 0px;
//     transform: scale(1);
//     border-radius: 0px;
//   }
// }

// @keyframes square4 {
//   0% {
//     top: 50%;
//     left: 50%;
//     transform: scale(1);
//     border-radius: 0px;
//   }
//   50% {
//     top: 50%;
//     left: 50%;
//     transform: scale(1);
//     border-radius: 0px;
//   }
//   62% {
//     top: calc(50% + 20px);
//     left: calc(50% + 20px);
//     transform: scale(0.35);
//     border-radius: 50%;
//   }
//   75% {
//     top: 50%;
//     left: 50%;
//     transform: scale(1);
//     border-radius: 0px;
//   }
//   100% {
//     top: 50%;
//     left: 50%;
//     transform: scale(1);
//   }
// }

@keyframes square1 {
  0% {
    top: 0px;
    left: 0px;
    transform: rotate(0deg);
  }
  12% {
    top: -20px;
    left: -20px;
  }
  25% {
    top: 0px;
    left: 0px;
    transform: rotate(360deg);
  }
  100% {
    top: 0px;
    left: 0px;
    transform: rotate(360deg);
  }
}

@keyframes square2 {
  0% {
    top: 0px;
    left: 50%;
    transform: rotate(0deg);
  }
  25% {
    top: 0px;
    left: 50%;
    transform: rotate(0deg);
  }
  37% {
    top: -20px;
    left: calc(50% + 20px);
  }
  50% {
    top: 0px;
    left: 50%;
    transform: rotate(360deg);
  }
  100% {
    top: 0px;
    left: 50%;
    transform: rotate(360deg);
  }
}

@keyframes square3 {
  0% {
    top: 50%;
    left: 0px;
    transform: rotate(0deg);
  }
  75% {
    top: 50%;
    left: 0px;
    transform: rotate(0deg);
  }
  87% {
    top: calc(50% + 20px);
    left: -20px;
  }
  100% {
    top: 50%;
    left: 0px;
    transform: rotate(360deg);
  }
}

@keyframes square4 {
  0% {
    top: 50%;
    left: 50%;
    transform: rotate(0deg);
  }
  50% {
    top: 50%;
    left: 50%;
    transform: rotate(0deg);
  }
  62% {
    top: calc(50% + 20px);
    left: calc(50% + 20px);
  }
  75% {
    top: 50%;
    left: 50%;
    transform: rotate(360deg);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: rotate(360deg);
  }
}
