@import "../../../styles/colors";

.person-motivators-cont {
}

.person-motivators-list {
  margin: 0px;
  padding: 15px 0px;
  box-sizing: border-box;
  list-style: none;
}

.person-motivators-list-item {
  padding-bottom: 10px;
  box-sizing: border-box;
  font-size: 12px;
  color: $dark;
}
