@import "../../../styles/colors";

// .team-team-tips-tip-list {
//   margin: 0px;
//   padding: 20px 0px;
//   width: 100%;
//   font-size: 14px;
// }

// .team-team-tips-tip {
//   padding: 0px;
//   list-style: none;
//   box-sizing: border-box;
//   position: relative;

//   &.team-team-tips-tip-not-first {
//     padding-top: 26px;
//   }
// }

// .team-team-tips-tip-dot {
//   position: absolute;
//   top: 20px;
//   left: 8px;
//   font-size: 10px;
// }

// * ========================
// * ========================

.team-team-tips-tip-list {
  margin: 0px;
  padding: 26px 0px 20px;
  width: 100%;
  font-size: 14px;
}

.team-team-tips-tip {
  padding: 0px;
  list-style: none;
  box-sizing: border-box;
  position: relative;
  display: flex;

  &.team-team-tips-tip-not-first {
    padding-top: 14px;
  }
}

.team-team-tips-tip-dot {
  position: absolute;
  top: 20px;
  left: 8px;
  font-size: 10px;
}

.team-team-tips-tip-icon {
  margin-right: 6px;

  &.team-team-tips-tip-icon-do {
    fill: $green;
  }

  &.team-team-tips-tip-icon-donot {
    fill: $red;
  }
}

.team-team-tips-tip-text {
  width: calc(100% - 22px);
  font-size: 12px;
  padding-top: 2px;
  box-sizing: border-box;
}
