@import "../../../styles/colors";

.person-interactions-toggle-cont {
  padding-top: 20px;
}

.person-interactions-compare-graph-cont {
  width: 100%;
  padding: 15px 0px;
  box-sizing: border-box;
}

.person-interactions-subtitle {
  margin: 0px;
  padding: 25px 0px 8px;
  color: $dark;
  font-size: 12px;
  font-weight: bold;
}

.person-interactions-tip {
  margin: 0px;
  font-size: 12px;
  color: $dark;
}
