@import "../../styles/colors";

.person-no-data-msg {
  margin-top: 75px;
  color: $dark;
  font-size: 16px;
  text-align: center;
}

// .content-person-back-button {
//   position: absolute;
//   left: 0px;
//   top: 8px;
//   font-size: 20px;
//   border: none !important;
//   display: flex;
//   align-items: center;
// }

.team-team-tips-tip-dot {
  position: absolute;
  top: 20px;
  left: 8px;
  font-size: 10px;
}

.team-team-tips-tip-icon {
  margin-right: 6px;

  &.team-team-tips-tip-icon-do {
    fill: $green;
  }

  &.team-team-tips-tip-icon-donot {
    fill: $red;
  }
}

.team-team-tips-tip-text {
  width: calc(100% - 22px);
  font-size: 12px;
  padding-top: 2px;
  box-sizing: border-box;
}
