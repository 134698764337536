@import "./styles/colors";

.main {
  // background-color: $grey-background;
  background-color: $white;
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $dark;
  font-family: "Open Sans", sans-serif;
  transition: background-color 400ms ease-in-out;
  box-sizing: border-box;
  position: relative;

  &.has-padding {
    padding: 8px 24px 8px 18px;
  }

  &.dark-mode {
    background-color: $darkmode-background;
    color: $darkmode-text;
    // color: $grey-light !important;

    *:not(.person-header-disc-profile-icon) {
      color: $darkmode-text;
      // color: $grey-light !important;
      // fill: $white !important;
    }
  }
}

.text-color {
  color: $dark;

  .main.dark-mode & {
    color: $white;
  }
}

.interface-section {
  min-height: 100%;
  min-width: 100%;
  box-sizing: border-box;
}

.main-session-info {
  position: absolute;
  top: 6px;
  left: 0px;
  width: 100%;
  text-align: center;
  font-size: 9px;
  opacity: 0;

  &:hover {
    opacity: 0.25;
  }
}

.main-logout-cont {
  position: absolute;
  bottom: 6px;
  left: 0px;
  width: 100%;
  text-align: center;
  opacity: 0.25;
  font-size: 9px;
}

.main-logo-cont {
  padding: 100px 0px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.main-logo-description {
  padding: 20px 10px 10px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  font-size: 12px;
}

.main-logo {
  padding: 12px;
  height: 125px;
  width: 125px;
  border-radius: 50%;
  box-sizing: border-box;
  // background-color: #fff;
  // box-shadow: 1px 3px 16px rgba(0, 0, 0, 0.25);
}

.disc-grid-bg {
  border-radius: 6px;
  // * [1] - Mimicking Original KVault Designs
  background: linear-gradient(
      133.15deg,
      lighten($disc-d-light, 5%) 3.02%,
      $disc-d-light 100%
    ),
    linear-gradient(
      132.3deg,
      lighten($disc-i-light, 5%) 8.78%,
      $disc-i-light 100%
    ),
    linear-gradient(
      136.49deg,
      lighten($disc-c-light, 5%) 10.1%,
      $disc-c-light 100%
    ),
    linear-gradient(
      133.03deg,
      lighten($disc-s-light, 5%) 9.27%,
      $disc-s-light 100%
    );
  // * [2] - Hakha's Design
  // background: linear-gradient(133.15deg, $disc-d 3.02%, $disc-d-dark 100%),
  //   linear-gradient(132.3deg, $disc-i 8.78%, $disc-i-dark 100%),
  //   linear-gradient(136.49deg, $disc-c 10.1%, $disc-c-dark 100%),
  //   linear-gradient(133.03deg, $disc-s 9.27%, $disc-s-dark 100%);
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: top left, top right, bottom left, bottom right;

  &.disc-grid-bg-with-grid {
    position: relative;

    &::after {
      content: "";
      border: 1px solid rgba(0, 0, 0, 0.1);
      width: 50%;
      height: 50%;
      box-sizing: border-box;
      position: absolute;
      left: 25%;
      top: 25%;
    }
  }
}
