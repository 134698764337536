@import "../styles/colors";

.main-settings-open-button-cont {
  position: absolute;
  padding: 6px;
  box-sizing: border-box;
  right: 6px;
  top: 6px;
  background-color: none;
  border: none;
  z-index: 1000;
  fill: $dark;
}

.main-settings-open-button {
  fill: $dark;

  .main.dark-mode & {
    fill: $darkmode-text;
  }
}

.toplevel-nomsoffice-error-cont {
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  max-width: 450px;
  align-self: center;
}

.toplevel-nomsoffice-error-title {
  margin: 0px;
  padding: 10px 0px;
  font-size: 24px;
  color: $dark;
}

.toplevel-nomsoffice-error-text {
  font-size: 14px;
  color: $dark;
}

.active-version-string {
  position: absolute;
  top: 10px;
  left: 0px;
  width: 100%;
  text-align: center;
  opacity: 0.4;
  font-size: 14px;
  margin: 0px;
}
