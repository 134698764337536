@import "../../../styles/colors";

.nav-bar {
  padding: 8px 0px;
  width: 100%;
  box-sizing: border-box;
  color: $dark;
}

.nav-bar-btn {
  padding: 4px;
  background: transparent;
  border: none;
  box-sizing: border-box;
  font-size: 20px;
  color: inherit;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  .main.dark-mode & {
    * {
      fill: $darkmode-text !important;
    }
  }
}
