@import "../../styles/colors";
@import "../../styles/animations";

.login-main-wrapper {
  padding: 12px;
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  background-color: $dark-background;
  // background-image: url("/background-pattern-1.png");
  // background-repeat: repeat;
  background-image: url("/asanoha-dark-5percent.png");
  background-repeat: repeat;
  align-items: center;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
}

.logon-inner-wrapper {
  margin: 10px auto;

  padding: 6px;
  box-sizing: border-box;
  width: 100%;
  max-width: 530px;
  border-radius: 3px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  animation: slideInFromBottomSlight 800ms ease-in-out;
}

.logon-inner-wrapper-animation {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  min-width: 200%;
  min-height: 200%;
  background: linear-gradient(to right, $disc-d 0% 50%, $disc-i 50% 100%),
    linear-gradient(to right, $disc-c 0% 50%, $disc-s 50% 100%);
  background-size: 100% 50%, 100% 50%;
  background-position-x: 0%, 0%;
  background-position-y: 0%, 100%;
  background-repeat: no-repeat, no-repeat;
  z-index: -1;
  animation: spinInCircle 100s linear infinite;
}

.logon-inputs-box {
  padding: 8px;
  // margin: 10px auto;
  width: 100%;
  max-width: 450px;
  box-sizing: border-box;
  border: 1px solid #ececec;
  background-color: $white;
  color: $black;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  // animation: slideInFromBottomSlight 800ms ease-in-out;
}

.login-error-msg {
  color: red;
  font-size: 11px;
  margin: 0px;
  padding: 4px 0px;
  box-sizing: border-box;
  white-space: pre-wrap;
}

.logon-title {
  margin: 10px 0px 20px;
  box-sizing: border-box;
  text-align: center;
  font-size: 25px;
  color: $dark;
}

.logon-title-images {
  padding: 10px 0px 10px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logon-title-crane {
  height: 35px;
  width: 35px;
}

.logon-title-logo {
  width: 150px;
}

.logon-blurb {
  margin: 0px;
  padding: 10px 10px 0px;
  box-sizing: border-box;
  // font-weight: bold;
  font-size: 12px;
  text-align: center;

  &.logon-blurb-2 {
    padding-bottom: 20px;
  }
}

.login-failedretrieval-msg {
  margin: 0px;
  padding: 0px 0px 8px;
  font-size: 8px;
  color: $dark;
}

.logon-input-label {
  font-size: 20px;
}

.logon-input-cont {
  margin-bottom: 12px;
}

.login-button {
  margin: auto auto 0px;
  height: 35px;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}

.login-signup-button {
  padding: 20px 0px 0px;
  box-sizing: border-box;
  font-size: 10px;
}

.login-teamtools-img-cont {
  padding: 10px 0px 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.login-teamtools-img {
  width: 64px;
  height: 11px;
}

.logon-links-cont {
  padding: 8px 0px;
  display: flex;
  justify-content: center;
}

.logon-link {
  font-size: 10px;
  color: $grey;
  margin: 0px 10px;
}

@keyframes spinInCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
