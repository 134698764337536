@import "../../../styles/colors";

.team-group-style-title {
  margin: 0px;
  padding: 12px 0px;
  font-size: 32px;
  box-sizing: border-box;
}

.team-group-style-description {
  margin: 0px;
  padding: 0px 0px 16px;
  font-size: 12px;
  box-sizing: border-box;
}

.team-group-style-title-letter {
  padding-right: 10px;
  font-size: inherit;

  &.title-letter-d {
    .main.dark-mode & {
      color: $disc-d !important;
    }
  }

  &.title-letter-i {
    .main.dark-mode & {
      color: $disc-i !important;
    }
  }

  &.title-letter-s {
    .main.dark-mode & {
      color: $disc-s !important;
    }
  }

  &.title-letter-c {
    .main.dark-mode & {
      color: $disc-c !important;
    }
  }
}

.content-team-group-style-title-letter-value {
  padding-right: 10px;
  font-size: 8px;
  position: absolute;
  bottom: -6px;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.team-group-style-stressed-toggle-cont {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}

.team-group-style-stressed-toggle {
}

.team-group-style-stressed-toggle-text {
  font-size: 14px;
  text-decoration: none;
}
