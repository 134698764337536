@import "../../../styles/colors";
@import "../../../styles/animations";
@import "../../../styles/variables";

.logon-multiple-sessions-found-cont {
  // padding: 26px 64px;
  min-height: 200px;

  // @media #{$mobile-top} {
  //   padding: 20px;
  //   max-width: 90vw;
  //   border-radius: 5px;
  // }
}

.logon-multiple-sessions-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.logon-multiple-sessions-list-item {
  &:nth-of-type(n + 2) {
    margin-top: 12px;
  }
}

.logon-multiple-sessions-list-item-button {
  padding: 6px 10px;
  border: 1px solid $grey;
  border-radius: 5px;
  background-color: $white;
  animation: slideInFromRightSlight 600ms ease-in-out;
  width: 100%;
  display: flex;
  flex-direction: row;

  &:hover {
    cursor: pointer;
  }
}

.logon-multiple-sessions-list-item-userthumb-cont {
  padding: 0px 10px 0px 0px;
  box-sizing: border-box;
}

.logon-multiple-sessions-list-item-inner-cont {
  //   width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
}

.logon-multiple-sessions-list-item-username {
  margin-bottom: 2px;
  width: 100%;
  text-align: left;
  display: block;
  color: $dark;
  font-size: 14px;
  //   font-weight: bold;
}

.logon-multiple-sessions-list-item-domainname {
  width: 100%;
  text-align: left;
  display: block;
  color: $grey;
  font-size: 11px;
  font-weight: bold;
}
