// * DISC Colors

// * [1] First iteration of Colors
// $disc-green: #6ab36b;
// $disc-blue: #3bb2eb;
// $disc-yellow: #ffc52c;
// $disc-red: #bd487d;

$lighten-value: 20;
// * [2] Hakha's colors
$disc-green-darker: #0d7746;
$disc-blue-darker: #1073c7;
$disc-yellow-darker: #f89a30;
$disc-red-darker: #8f3688;
$disc-green: #67b26a;
$disc-blue: #2597d4;
$disc-yellow: #fec42c;
$disc-red: #af4986;
$disc-green-light: #badcbb;
$disc-blue-light: #9bd0ed;
$disc-yellow-light: #fef0ca;
$disc-red-light: #dcabc8;

$disc-d: $disc-red;
$disc-i: $disc-blue;
$disc-s: $disc-green;
$disc-c: $disc-yellow;
$disc-d-dark: $disc-red-darker;
$disc-i-dark: $disc-blue-darker;
$disc-s-dark: $disc-green-darker;
$disc-c-dark: $disc-yellow-darker;
$disc-d-light: $disc-red-light;
$disc-i-light: $disc-blue-light;
$disc-s-light: $disc-green-light;
$disc-c-light: $disc-yellow-light;

// * Misc. Colors
$blue-link: #147ac9;
$orange-active: #f89d1f;
$grey-background: #efefef;
$grey: #9e9e9e;
$grey-light: #cecece;
$grey-mid: #ededed;
$grey-mid-light: #babcbd;
$grey-mid-high-light: #e5e5e5;
$grey-lighter: #d9d9d9;
$white: #fff;
$black: #000;
$dark: #223958;
$dark-background: #435369;
$darkmode-background: #1d2229;
$darkmode-text: #a7b0bc;
$burgandy-admin: #7b5369;
$red: #eb5757;
$green: #6ab36b;

// * Gradients
$placeholder-gradient: linear-gradient(
  to bottom right,
  lighten($dark, 6%),
  $dark
);
