@import "../../../../styles/colors";

.cc-disc-plot-graph-column-disctribution-row {
  box-sizing: border-box;
  position: absolute;
  height: calc(100% / 16);
  background-color: transparentize($grey, 0.4);
  //   animation: allowGrow 800ms ease-in-out;
  transition: width 600ms ease-in-out;

  &.cc-disc-plot-graph-column-disctribution-row-d {
    background-color: transparentize($disc-d, 0.4);
  }

  &.cc-disc-plot-graph-column-disctribution-row-i {
    background-color: transparentize($disc-i, 0.4);
  }

  &.cc-disc-plot-graph-column-disctribution-row-s {
    background-color: transparentize($disc-s, 0.4);
  }

  &.cc-disc-plot-graph-column-disctribution-row-c {
    background-color: transparentize($disc-c, 0.4);
  }
}

@keyframes allowGrow {
  0% {
    max-width: 0%;
  }
  100% {
    max-width: 100%;
  }
}
