@import "../../styles/colors";

// .team-section-cont {
//   padding: 20px 0px;
//   box-sizing: border-box;
//   border-top: 1px solid $grey-lighter;
// }

.team-main-cont {
  padding: 0px 8px;
  box-sizing: border-box;
}

.team-main-title {
  color: $dark;
  margin: 15px 0px 5px;
  font-size: 20px;
}

.team-main-preview-link-cont {
  // margin: 0px 0px 25px;
  margin: 0px 0px 15px;
}

.team-main-preview-link {
  font-size: 12px;
  color: $dark;

  &:hover {
    cursor: pointer;
  }
}
