@import "../../styles/colors";

.welcome-recipient-list {
  padding: 0px;
}

.welcome-recipient-list-item {
  padding: 3px 6px;
  margin-bottom: 10px;
  list-style: none;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: $white;

  p {
    margin: 0px;
  }
}

.welcome-recipient-list-item-disc-values {
  padding: 8px 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
}

.new-message-noliveupdates-refresh-button {
  font-size: inherit;
}

.new-message-noliveupdates-refresh-button {
  margin-left: 10px;
  color: $dark-background;
}
